html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  /* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
  height: 100%;
  overflow: hidden;
}

body {
  background: transparent;
  background: #151515;
  color: #fff;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}


.loader-screen .loader-load .button-load {
  width: calc(50px + 4vw);
  height: calc(50px + 4vw);
  background: #3CCF4E;
  border-radius: 50%;
  margin: 0 auto 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  transition: .5s;
}

.loader-screen .loader-load p {
  font-weight: 400;
  font-size: calc(12px + 1.2vw);
  text-align: center;
  color: #FFFFFF;
}

.loader-screen .loader-load .button-load svg {
  width: calc(15px + 1vw);
}

.loader-screen .loader-load .button-load:hover {
  transform: scale(1.1);
  background: #3CCF4E;
}



.loader-view-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-view-progress .loader-inner {
  text-align: center;
}

.loader-view-progress .loader-inner .text-wrapper .title {
  font-weight: 350;
  font-size: 128px;
  text-align: center;
  color: #FFFFFF;
  line-height: 1;
  color: #3CCF4E;
}

.loader-view-progress .loader-inner .text-wrapper .text {
  font-weight: 375;
  font-size: 20px;
  line-height: 1;
  color: #FFFFFF;
  font-style: italic;
}

.loader-view-progress .loader-inner {
  position: relative;
}

.loader-view-progress .loader-inner .loader-wrapper circle.active {
  fill-opacity: 1;
}

.loader-view-progress .loader-inner .loader-wrapper svg {
  vertical-align: middle;
}

.loader-view-progress .loader-inner .text-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-view-progress .loader-inner .loader-wrapper svg {
  width: 100%;
  height: 100%;
}

.loader-view-progress .loader-inner .text-wrapper {
  width: 100%;
}

/***Specially for Bigger Screens****/

.loader-view-progress .loader-inner .loader-wrapper {
  width: calc(200px + 6vw);
  height: calc(200px + 6vw);
  margin: auto;
}

.loader-view-progress .text-inner {
  font-weight: 400;
  font-size: calc(12px + .8vw);
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  margin-top: calc(10px + 1.5vw);
}

.loader-view-progress .loader-inner .text-wrapper .title {
  font-size: calc(50px + 4vw);
}

.loader-view-progress .loader-inner .text-wrapper .text {
  font-size: calc(16px + .25vw);
}